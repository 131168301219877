<template>Previewing: {{ preview }}</template>

<script>
// To use: browse dev-dialog-preview/<DialogName>
import { useDialogStore } from '@/stores/DialogStore'

export default {
	name: 'DevDialogPreview',
	props: {
		preview: String,
	},
	setup() {
		const dialogStore = useDialogStore()
		return { dialogStore }
	},
	created() {
		this.dialogStore.dialogByName(this.preview, {
			url: 'artr.io/3blm',
			text: 'Vanitas, 2016 by Kris Martinn',
			imgUrl: '/img/art/jpg/000173449e13cd604/kris-martin/vanitas/large/kris-martin--vanitas.webp',
			id: 'mCisa1mesRs',
		})
	},
}
</script>

<style scoped lang="scss"></style>
